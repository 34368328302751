// formkit.config.js
import type { FormKitOptions } from '@formkit/core';
import type { FormKitInputs } from '@formkit/inputs';

import { bindings } from '@formkit/vue';
import { createValidationPlugin } from '@formkit/validation';
import { createI18nPlugin } from '@formkit/i18n';
import { inputs, locales } from './formkit';

import { createThemePlugin } from '@formkit/themes';
import { createLibraryPlugin, text, form, number, tel, password, email as emailInput, checkbox, group, radio, list, meta, file, textarea } from '@formkit/inputs';
import { required, email, length, accepted, confirm, min } from '@formkit/rules';

const validation = createValidationPlugin({ required, email, length, accepted, confirm, min });
const library = createLibraryPlugin({ ...inputs, tel, text, number, form, password, email: emailInput, checkbox, group, radio, list, meta, file, textarea });
const i18n = createI18nPlugin(locales);

const themePlugin = createThemePlugin(undefined, {
	check: '<svg viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 10.0769L9.5625 17L24 2" stroke="CurrentColor" stroke-width="4.66667"/></svg>',
	close: '<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 1L7.5 7.5M7.5 7.5L1 14M7.5 7.5L14 14M7.5 7.5L1 1" stroke="#DA4727" stroke-width="1.2" /></svg>',
	true: ''
});

export default {
	plugins: [library, validation, i18n, bindings, themePlugin],

} satisfies FormKitOptions;

declare module '@formkit/inputs' {
	interface FormKitInputProps<Props extends FormKitInputs<Props>> {
		'counter': {
			type: 'counter',
			max?: number,
			min?: number,
			value: number
		},
		'rating': {
			type: 'rating',
			value?: number,
			max?: number
		},
		'toggleButtons': {
			type: 'toggleButtons',
			value?: string | number,
			options: any,
		},
		"datepicker": {
			type: 'datepicker',
		},
		'selectC': {
			type: 'selectC',
			value?: string,
			options: any
		},
		'selectF': {
			type: 'selectC',
			value?: string,
			options: { value: string | number | (Record<string, unknown> & { id: string | number }), label: string, icon?: string | number, }[] | undefined,
			filterInputPlaceholder?: string,
			getOptionsFilter: ((search: string) => Promise<{ value: string | number | (Record<string, unknown> & { id: string | number }), label: string, icon?: string | number, }[]>)
		},
		'tagList': {
			type: 'tagList',
			value?: string[],
		},
	}
}
