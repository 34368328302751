<template>
	<DatePickerRoot :id="context.id" v-model="date" hideTimeZone granularity="day" locale="ru">
		<DatePickerTrigger :class="context.classes.input">
			<DatePickerField v-slot="{ segments }" :class="context.classes.dateField">
				<DatePickerInput @click.stop="() => true" v-for="item in segments" :key="item.part" :part="item.part" :class="item.part === 'literal' ? context.classes.dateFieldLiteral : context.classes.dateFieldSegment">
					{{ item.value }}
				</DatePickerInput>
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="w-6 h-6 iconify iconify--radix-icons" width="1em" height="1em" viewBox="0 0 15 15">
					<path fill="currentColor" fill-rule="evenodd"
						d="M4.5 1a.5.5 0 0 1 .5.5V2h5v-.5a.5.5 0 0 1 1 0V2h1.5A1.5 1.5 0 0 1 14 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-10A1.5 1.5 0 0 1 1 12.5v-9A1.5 1.5 0 0 1 2.5 2H4v-.5a.5.5 0 0 1 .5-.5M10 3v.5a.5.5 0 0 0 1 0V3h1.5a.5.5 0 0 1 .5.5V5H2V3.5a.5.5 0 0 1 .5-.5H4v.5a.5.5 0 0 0 1 0V3zM2 6v6.5a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V6zm5 1.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M9.5 7a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1m1.5.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m.5 1.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M9 9.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M7.5 9a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M5 9.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0M3.5 9a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1M3 11.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m2.5-.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1m1.5.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m2.5-.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1"
						clip-rule="evenodd"></path>
				</svg>
			</DatePickerField>
		</DatePickerTrigger>

		<DatePickerContent align="center" :side-offset="16" :class="context.classes.popoverContent">
			<DatePickerCalendar v-slot="{ weekDays, grid }" :class="context.classes.calendar">
				<DatePickerHeader :class="context.classes.calendarHeader">
					<DatePickerPrev :class="context.classes.calendarNavButton">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-left h-4 w-4">
							<path d="m15 18-6-6 6-6"></path>
						</svg>
					</DatePickerPrev>
					<DatePickerHeading :class="context.classes.calendarHeading" />
					<DatePickerNext :class="context.classes.calendarNavButton">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-right h-4 w-4">
							<path d="m9 18 6-6-6-6"></path>
						</svg>
					</DatePickerNext>
				</DatePickerHeader>
				<div :class="context.classes.calendarWrapper">
					<DatePickerGrid v-for="month in grid" :key="month.value.toString()" :class="context.classes.calendarGrid">
						<DatePickerGridHead>
							<DatePickerGridRow :class="context.classes.calendarGridRow">
								<DatePickerHeadCell v-for="day in weekDays" :key="day" :class="context.classes.calendarHeadCell">
									{{ day }}
								</DatePickerHeadCell>
							</DatePickerGridRow>
						</DatePickerGridHead>
						<DatePickerGridBody>
							<DatePickerGridRow v-for="(weekDates, index) in month.rows" :key="`weekDate-${index}`" :class="context.classes.calendarGridRow">
								<DatePickerCell v-for="weekDate in weekDates" :key="weekDate.toString()" :date="weekDate" :class="context.classes.calendarCell">
									<DatePickerCellTrigger :day="weekDate" :month="month.value" :class="context.classes.calendarCellTrigger" />
								</DatePickerCell>
							</DatePickerGridRow>
						</DatePickerGridBody>
					</DatePickerGrid>
				</div>
			</DatePickerCalendar>
		</DatePickerContent>
	</DatePickerRoot>
</template>
<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core';
const props = defineProps<{
	context: FormKitFrameworkContext
}>();

import {
	DatePickerCalendar,
	DatePickerCell,
	DatePickerCellTrigger,
	DatePickerContent,
	DatePickerField,
	DatePickerGrid,
	DatePickerGridBody,
	DatePickerGridHead,
	DatePickerGridRow,
	DatePickerHeadCell,
	DatePickerHeader,
	DatePickerHeading,
	DatePickerNext,
	DatePickerPrev,
	DatePickerRoot,
	DatePickerTrigger,
} from 'radix-vue'

const date = ref()

watch(() => props.context.value, (newValue) => {
	date.value = newValue
})

watch(date, (newValue) => {
	props.context.node.input(newValue)
})


</script>