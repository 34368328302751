import type {
    FormKitValidationMessages
} from '@formkit/validation';
import { sentence as s, list, date, order } from '../formatters';
const ui = {
    submit: 'Отправить'
};

const validation: FormKitValidationMessages = {
    /* accepted({ name }): string {
            return `Пожалуйста, примите ${name}.`
        }, */

    required({ name }) {
        return 'Поле обязательно к заполнению.';
    },
    length({ name, args: [first = 0, second = Infinity] }) {
        const min = Number(first) <= Number(second) ? first : second;
        const max = Number(second) >= Number(first) ? second : first;
        if (min == 1 && max === Infinity) {
            /* <i18n case="Shown when the length of the user-provided value is not at least one character."> */
            return `Поле ${name} должно содержать минимум один символ.`;
            /* </i18n> */
        }
        if (min == 0 && max) {
            /* <i18n case="Shown when first argument supplied to the rule is 0, and the user-provided value is longer than the max (the 2nd argument) supplied to the rule."> */
            return `Длина поля ${name} должна быть меньше или равна ${max} символам.`;
            /* </i18n> */
        }
        if (min === max) {
            /* <i18n case="Shown when first and second argument supplied to the rule are the same, and the user-provided value is not any of the arguments supplied to the rule."> */
            return `Длина ${name} должна составлять ${max} символов.`;
            /* </i18n> */
        }
        if (min && max === Infinity) {
            /* <i18n case="Shown when the length of the user-provided value is less than the minimum supplied to the rule and there is no maximum supplied to the rule."> */
            return `Длина поля ${name} должна быть больше или равна ${min} символам.`;
            /* </i18n> */
        }
        /* <i18n case="Shown when the length of the user-provided value is between the two lengths supplied to the rule."> */
        return `Длина поля ${name} должна быть между ${min} и ${max} символами.`;
        /* </i18n> */
    },
    min({ name, node: { value }, args }) {
        if (Array.isArray(value)) {
            return `Не может быть выбрано меньше, чем ${args[0]}.`
        }
        return `Поле должно быть не менее, чем ${args[0]}.`
    },
    /**
        * Shown when the user-provided value is not a valid email address.
        * @see {@link https://formkit.com/essentials/validation#email}
        */
    email: 'Пожалуйста, введите действительный электронный адрес.',
    confirm: 'Пароли не совпадают'
};

export const ru = { ui, validation };
