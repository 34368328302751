<template>
	<button ref="inputEl" @click="isShow = !isShow" type="button" :class="context.classes.input">
		<NuxtImgC v-if="selectOptions?.icon && typeof selectOptions?.icon === 'number'" :key="selectOptions?.icon" :class="context.classes.selectPrefixIcon" :src="String(selectOptions?.icon)" alt="" :modifiers="{ h: '35px' }" />
		<img v-else-if="selectOptions?.icon" :class="context.classes.selectPrefixIcon" :src="String(selectOptions?.icon)" alt="" />
		<span :class="!selectOptions ? context.classes.placeholder : context.classes.selectText">{{ selectOptions?.label ?? context.attrs.placeholder }}</span>
		<img :class="context.classes.selectIcon" src="/assets/images/svg/arrow-down-thin.svg" alt="">
	</button>
	<div ref="dropdownWrapperEl" v-show="isShow" :class="context.classes.dropdownWrapper">
		<ul role="listbox" :class="context.classes.listbox">
			<li v-for="option in context.options" @click="handleInput(option.value)" :class="[context.classes.listItem, compareValue(option.value) ? context.classes.listItemSelect : null]">
				<NuxtImgC v-if="option?.icon && typeof option?.icon === 'number'" :class="context.classes.listItemIcon" :src="String(option.icon)" alt="" :modifiers="{ h: '35px' }" />
				<img v-else-if="option?.icon" :class="context.classes.listItemIcon" :src="String(option.icon)" alt="">
				{{ option.label }}
			</li>
		</ul>
	</div>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';
const props = defineProps<{
	context: FormKitFrameworkContext & {
		options: { value: string | number | (Record<string, unknown> & { id: string | number }), label: string, icon?: string, }[],
	}
}>();

const isShow = ref(false)

const close = () => {
	isShow.value = false
}

const inputEl = ref(null);
const dropdownWrapperEl = ref(null);

onClickOutside(inputEl, (event) => {
	if (isShow.value) {
		isShow.value = false;
		props.context.handlers.blur();
	}
}, { ignore: [dropdownWrapperEl] });

const selectOptions = computed(() => props.context.options?.find(a => compareValue(a.value)))

function handleInput(value: any) {
	if (value === props.context.value) {
		props.context.node.input(null)
		return close()
	}
	props.context.node.input(value)
	close()
}

/* @ts-ignore */
const compareValue = (value: string | number | (Record<string, unknown> & { id: string | number })) => value?.id ? value.id === props.context.value.id : value === props.context.value
</script>
