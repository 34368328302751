import type {
	FormKitValidationMessages
} from '@formkit/validation';
const ui = {
	submit: 'Отправить'
};

const validation: FormKitValidationMessages = {
	required({ name }) {
		return 'The field is required to be filled in.';
	},
	length({ name, args: [first = 0, second = Infinity] }) {
		const min = Number(first) <= Number(second) ? first : second
		const max = Number(second) >= Number(first) ? second : first
		if (min == 1 && max === Infinity) {
			/* <i18n case="Shown when the length of the user-provided value is not at least one character."> */
			return `${name} must be at least one character.`
			/* </i18n> */
		}
		if (min == 0 && max) {
			/* <i18n case="Shown when first argument supplied to the rule is 0, and the user-provided value is longer than the max (the 2nd argument) supplied to the rule."> */
			return `${name} must be less than or equal to ${max} characters.`
			/* </i18n> */
		}
		if (min === max) {
			/* <i18n case="Shown when first and second argument supplied to the rule are the same, and the user-provided value is not any of the arguments supplied to the rule."> */
			return `${name} should be ${max} characters long.`
			/* </i18n> */
		}
		if (min && max === Infinity) {
			/* <i18n case="Shown when the length of the user-provided value is less than the minimum supplied to the rule and there is no maximum supplied to the rule."> */
			return `${name} must be greater than or equal to ${min} characters.`
			/* </i18n> */
		}
		/* <i18n case="Shown when the length of the user-provided value is between the two lengths supplied to the rule."> */
		return `${name} must be between ${min} and ${max} characters.`
		/* </i18n> */
	},
	min({ name, node: { value }, args }) {
		if (Array.isArray(value)) {
		  return `Cannot have fewer than ${args[0]}.`
		}
		return `must be at least ${args[0]}.`
	  },
	/**
		* Shown when the user-provided value is not a valid email address.
		* @see {@link https://formkit.com/essentials/validation#email}
		*/
	email: 'Please enter a valid email address.',
	confirm: 'Passwords don\'t match'
};

export const en = { ui, validation };
