import { createInput } from '@formkit/vue';
import type { FormKitTypeDefinition } from '@formkit/core';

import Counter from './Counter.vue';
import Rating from './Rating.vue';
import toggleButtons from './toggleButtons.vue';
import datepicker from './datepicker.vue';
import selectC from './SelectC.vue';
import selectF from './SelectF.vue';
import tagList from './TagList.vue';

const counterDefinition: FormKitTypeDefinition = createInput(Counter, {
  props: ['max', 'min']
});

/* const autocompleteDefinition: FormKitTypeDefinition = createInput(Autocomplete, {
  props: ['options', 'dynamic'],
  family: 'text'
}); */

const ratingDefinition: FormKitTypeDefinition = createInput(Rating, {
  props: ['max']
});

/* const selectGroupDefinition: FormKitTypeDefinition = createInput(SelectGroup, {
  props: ['options']
}); */

const selectCDefinition: FormKitTypeDefinition = createInput(selectC, {
  props: ['options']
});

const selectFDefinition: FormKitTypeDefinition = createInput(selectF, {
  props: ['options', 'filterInputPlaceholder', 'getOptionsFilter']
});

const toggleButtonsDefinition: FormKitTypeDefinition = createInput(toggleButtons, {
  props: ['options']
});

const datepickerDefinition: FormKitTypeDefinition = createInput(datepicker, {

});
const tagListDefinition: FormKitTypeDefinition = createInput(tagList, {

});

export const inputs = {
  counter: counterDefinition,
  rating: ratingDefinition,
  toggleButtons: toggleButtonsDefinition,
  datepicker: datepickerDefinition,
  selectC: selectCDefinition,
  selectF: selectFDefinition,
  tagList: tagListDefinition
};
