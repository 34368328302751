<template>
	<input v-bind="context.attrs" type="text" v-model="text" :class="context.classes.input">
	<button @click="addTag(text)" :class="context.classes.addButton" type="button">Добавить</button>
	<ul role="listbox" :class="context.classes.listbox">
		<li v-for="(option, i) in context.value" :key="i" :class="context.classes.listItem">
			<span :class="context.classes.listItemText">{{ option }}</span>
			<button @click="removeTag(i)" type="button" :class="context.classes.closeBtn"><img src="/assets/images/svg/x.svg" alt=""></button>
		</li>
	</ul>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';
const props = defineProps<{
	context: FormKitFrameworkContext
}>();

const text = ref()

const addTag = (value: string) => {
	if (!value) return
	props.context.node.input([...props.context.value ?? [], value])
	text.value = null
}

const removeTag = (index: number) => {
	const array = [...props.context.value]
	array.splice(index, 1)
	props.context.node.input([...array])
}
</script>
