<template>
	<button ref="inputEl" @click="isShow = !isShow" type="button" :class="context.classes.input">
		<!-- <NuxtImgC v-if="selectOptions?.icon && typeof selectOptions?.icon === 'number'" :key="selectOptions?.icon" :class="context.classes.selectPrefixIcon" :src="String(selectOptions?.icon)" alt="" sizes="20px"/>
		<img v-else-if="selectOptions?.icon" :class="context.classes.selectPrefixIcon" :src="String(selectOptions?.icon)" alt="" /> -->
		<span :class="!selectOptions ? context.classes.placeholder : context.classes.selectText">{{ selectOptions?.label ?? context.attrs.placeholder }}</span>
		<img :class="context.classes.selectIcon" src="/assets/images/svg/arrow-down-thin.svg" alt="">
	</button>
	<div ref="dropdownWrapperEl" v-show="isShow" :class="[context.classes.dropdownWrapper, isShow ? context.classes.dropdownWrapperActive : '']">
		<div :class="context.classes.filterInputInner">
			<img :class="context.classes.filterInputPrefixIcon" src="/assets/images/svg/search.svg" alt="">
			<input v-model="input" type="text" :class="context.classes.filterInput" :placeholder="context.filterInputPlaceholder">
		</div>
		<ul v-show="(status === 'success' || !input) && options?.length" role="listbox" :class="context.classes.listbox">
			<li v-for="(option, i) in options" :key="i" @click="handleInput(option.value)" :class="[context.classes.listItem, compareValue(option.value) ? context.classes.listItemSelect : null]">
				<!-- <NuxtImgC v-if="option?.icon && typeof option?.icon === 'number'" :class="context.classes.listItemIcon" :src="String(option.icon)" alt="" />
				<img v-else-if="option?.icon" :class="context.classes.listItemIcon" :src="String(option.icon)" alt=""> -->
				{{ option.label }}
			</li>
		</ul>
		<span v-if="status === 'pending' && input" :class="[{ 'btn-loaded': status === 'pending' && input }, context.classes.filterLoaded]"></span>
		<span v-if="status === 'error' && input">{{ $t('interface.error_when_receiving_data') }}</span>
		<span v-if="status === 'success' && input && options?.length === 0">{{ $t('interface.nothing_found_for_query') }}</span>
	</div>
</template>

<script lang="ts" setup>
import type { FormKitFrameworkContext } from '@formkit/core';

type Options = { value: string | number | (Record<string, unknown> & { id: string | number }), label: string, icon?: string | number, }
const props = defineProps<{
	context: FormKitFrameworkContext & {
		options: Options[],
		getOptionsFilter: ((search: string) => Promise<Options[]>),
		filterInputPlaceholder?: string
	}
}>();

const isShow = ref(false)

const close = () => {
	isShow.value = false
}

const inputEl = ref(null);
const input = ref()
const dropdownWrapperEl = ref(null);

onClickOutside(inputEl, (event) => {
	if (isShow.value) {
		isShow.value = false;
		props.context.handlers.blur();
	}
}, { ignore: [dropdownWrapperEl] });


const options = computed(() => {
	if (input.value) return optionsFilter.value
	return props.context.options
})

const status = ref<'pending' | 'success' | 'error' | 'idle'>('idle')

const optionsFilter = ref<{ value: string | number | (Record<string, unknown> & { id: string | number }), label: string, icon?: string | number }[]>()
watchDebounced(input, async (newValue) => {	/* status.value = 'pending'; */
	status.value = 'pending'
	await props.context.getOptionsFilter(newValue).then((res) => {
		optionsFilter.value = res
		status.value = 'success';
	}).catch(() => {
		status.value = 'error';
	});
}, { debounce: 300 });

const selectOptions = ref()
watchEffect(() => {
	return selectOptions.value = props.context.options?.find(a => {
		if (a.value?.id) {
			return a.value.id === props.context.value.id
		}
		return a.value == props.context.value
	})
})

function handleInput(value: any) {
	if (value === props.context.value) {
		props.context.node.input(null)
		return close()
	}
	props.context.node.input(value)
	close()
}

/* @ts-ignore */
const compareValue = (value: string | number | (Record<string, unknown> & { id: string | number })) => value?.id ? value.id === props.context.value.id : value === props.context.value
</script>
