<template>
	<div :class="context.classes.options">
		<button v-for="option in context.options" @click="handleInput(option.value)" :class="[context.classes.option, compareValue(option.value) ? context.classes.optionActive : '']" type="button">
			<NuxtImgC v-if="option?.icon && typeof option?.icon === 'number'" :key="option?.icon" :class="context.classes.selectPrefixIcon" :src="String(option?.icon)" alt="" :modifiers="{ h: '35px' }" />
			<img v-else-if="option?.icon" :class="context.classes.optionIcon" :src="option.icon" alt="">
			{{ option.label }}
		</button>
	</div>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core';
const props = defineProps<{
	context: FormKitFrameworkContext & {
		options: [{ label: string, value: string | number | (Record<string, unknown> & { id: string | number }), icon?: string }],
	}
}>();

function handleInput(_value: any) {
	if (_value === props.context.value) return props.context.node.input(undefined);
	props.context.node.input(_value);
}

/* @ts-ignore */
const compareValue = (value: string | number | (Record<string, unknown> & { id: string | number })) => value?.id ? value.id === props.context.value?.id : value === props.context.value
</script>